<template>
  <div class="container">
    <div class="wrapper">
      <h1 :style="{ textAlign: 'center', marginBottom: '30px' }">
        Авторизация
      </h1>
      <a-form @submit.prevent="handleSubmit">
        <a-form-item>
          <a-input
            v-model="username"
            allow-clear
            placeholder="Имя пользователя"
          >
            <a-icon slot="prefix" style="color: rgba(0,0,0,.25)" type="user" />
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input-password
            id="current-password"
            v-model="password"
            autocomplete="current-password"
            allow-clear
            placeholder="Пароль"
          >
            <a-icon slot="prefix" style="color: rgba(0,0,0,.25)" type="lock" />
          </a-input-password>
        </a-form-item>
        <a-form-item class="button-wrapper">
          <a-button html-type="submit" type="primary">Войти</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import { LOGIN_USER } from '@/store/types'

export default {
  data: () => ({
    username: '',
    password: '',
  }),
  methods: {
    handleSubmit() {
      const { username, password } = this

      this.$store.dispatch(LOGIN_USER, { username, password }).catch(error =>
        this.$notification.error({
          message: 'Ошибка!',
          description: error.message,
        }),
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}

.wrapper {
  padding: 24px 24px 0;
  min-width: 400px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.12);
}

.button-wrapper {
  display: flex;
  justify-content: center;

  button,
  &::v-deep .ant-form-item-control-wrapper {
    width: 100%;
  }
}
</style>
